var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataReady)?_c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('ExpensesModal',{attrs:{"costTypes":_vm.costTypes,"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"existingItemModal":_vm.modalData.existingItem,"elementId":_vm.modalData.existingItemId},on:{"close":_vm.closeModal}})],1):_vm._e(),_c('v-form',[_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.costHeaders,"items":_vm.costList,"filter":_vm.filter,"search":_vm.search},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"},attrs:{"data-cy":"salaryCosts"}},[_vm._v(" Lønnskostnader ")]),_c('v-spacer'),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i lønnskostnader","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canUpsert)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Ny lønnskostnad","icon":"mdi-plus","data-cy":"openNewExpense"},on:{"handleClick":_vm.openNewExpense}}):_vm._e()],1)]},proxy:true},{key:"item.costTypeId",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.getExpenseTypeById(value, _vm.costTypes)))])]}},{key:"item.price",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.toString().replace(".", ",") + " kr")+" ")]}},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"displayExpense"},on:{"click":function($event){return _vm.displayExpense(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("vis lønnskostnad")])]),(_vm.canUpsert)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"editExpense"},on:{"click":function($event){return _vm.editExpense(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger lønnskostnad")])]):_vm._e()]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }